// main vuex store 


//// IMPORTS
import { jwtDecode } from "jwt-decode"
import { useCookies } from '@vueuse/integrations/useCookies'
import { updateAccentColors } from '@/plugins/vuetify'
import router from '@/plugins/router'


//// DEPLOYMENT CONSTANTS
const DEPLOYMENT_CHANNEL = import.meta.env.MODE
const DEPLOYMENT_DEBUGGING = DEPLOYMENT_CHANNEL === 'development'
const DEPLOYMENT_URL = import.meta.env.VITE_URL


//// COOKIE CONSTANTS
const COOKIE_EXPIRES_MAX = new Date(new Date().getTime() + 365 * 24 * 60 * 60 * 1000 * 10) // 10 years


//// APP CONSTANTS

// core apps
const APP_SHARE = 'share'
const APP_LOGIN = 'login'
const APP_ACCOUNT = 'account'
const APP_ADMIN = 'admin'
const APP_APPS = 'apps'

// feature apps
const APP_SKETCHURIZER = 'sketch'
const APP_MODULATOR = 'model'
const APP_TEXTURIZER = 'text'
const APP_BOXURIZER = 'box'


//// WINDOW CONSTANTS
const WINDOW_APPS = 'apps'
const WINDOW_PROTOTYPES = 'prototypes'
const WINDOW_DEFAULT = WINDOW_APPS


//// DESIGN CONSTANTS
const DESIGN_MODE_LIGHT = 'Light'
const DESIGN_MODE_DARK = 'Dark'
const DESIGN_MODE_DEFAULT = DESIGN_MODE_LIGHT
const DESIGN_THEME_DEFAULT = 0


//// RESOLUTION CONSTANTS
const RESOLUTION_THUMBNAIL_DEFAULT = 200


//// VUEX STORE
export const app = {
    namespaced: true,
    state: {
        // state
        initialized: false,

        // base
        version: '',
        window: WINDOW_DEFAULT,
        appname: '',

        // loading
        loading: {
            active: false,
            progress: -1, // 0..100 shows progress
            text: null
        },

        // security
        token: null,
        issued: null,
        expires: null,
        permanent: true,
        username: 'unauthorized',
        developer: false,
        permission: {     
            sketchurizer: false,
            modulator: false,
            texturizer: false,
            boxurizer: false
        },
        group: null,

        // design
        design: {
            mode: DESIGN_MODE_DEFAULT,
            theme: DESIGN_THEME_DEFAULT,
            vuetify: null
        },

        // additional (contains experimental)
        additional: null,

        // toast
        toast: {
            active: false,
            text: '',
            warning: false
        }
    },
    mutations: {
        SET_INITIALIZED(state)
        {
            state.initialized = true
        },
        SET_VERSION(state, version) 
        {
            state.version = version
        },
        SET_APPNAME(state, appname) 
        {
            state.appname = appname
        },
        SET_WINDOW(state, window)
        {
            state.window = window
        },
        RESET_LOADING(state)
        {
            state.loading.active = false
            state.loading.progress = -1
            state.loading.text = null
        },
        SET_LOADING_ACTIVE(state, active) 
        {
            state.loading.active = active
        },
        SET_LOADING_PROGRESS(state, progress)
        {
            state.loading.progress = progress
        },
        SET_LOADING_TEXT(state, text)
        {
            state.loading.text = text
        },
        SET_TOKEN(state, token) 
        {
            // reset state
            state.token = null
            state.issued = null
            state.expires = null
            state.username = 'unauthorized'
            state.developer = false
            state.permission.sketchurizer = false
            state.permission.modulator = false
            state.permission.texturizer = false
            state.permission.boxurizer = false
            state.group = null

            // only continue if token exists
            if (token)
            {
                try {
                    // decode token
                    const decodedToken = jwtDecode(token)

                    // update general state
                    state.token = token
                    state.issued = new Date(decodedToken.iat * 1000)
                    state.expires = new Date(decodedToken.exp * 1000)
                    state.username = decodedToken.sub
                    state.developer = decodedToken.developer
                    state.group = decodedToken.group

                    // update permission state
                    for (let permission of decodedToken.permission)
                    {
                        // ignore case
                        const permissionIgnoreCase = permission.toLowerCase()

                        switch (permissionIgnoreCase)
                        {
                            case 'sketchurizer':
                                state.permission.sketchurizer = true
                                break
                            case 'modulator':
                                state.permission.modulator = true
                                break
                            case 'boxurizer':
                                state.permission.boxurizer = true
                                break
                            case 'texturizer':
                                state.permission.texturizer = true
                                break
                        }
                    }

                    if (state.developer)
                    {
                        console.warn("user has developer permissions")
                        console.log(decodedToken)
                    }
                }
                catch (error) {
                    console.error('Your token is invalid.')
                }
            }
            
            // update cookie 
            const cookies = useCookies(['token'])
            if (state.token !== null && state.permanent) 
            {
                cookies.set('token', token, { expires: state.expires })
            }
            else 
            {
                cookies.set('token', null)
            }
        },
        SET_PERMANENT(state, permanent)
        {
            state.permanent = permanent            
        },
        SET_MODE(state, mode)
        {
            state.design.mode = mode

            // update cookie 
            const cookies = useCookies(['mode'])
            cookies.set('mode', mode, { expires: COOKIE_EXPIRES_MAX })
        },
        SET_THEME(state, theme) 
        {
            state.design.theme = theme

            // update cookie 
            const cookies = useCookies(['theme'])
            cookies.set('theme', theme, { expires: COOKIE_EXPIRES_MAX })

            // update vuetify
            updateAccentColors(theme)
        },
        SET_VUETIFY(state, vuetify)
        {
            state.design.vuetify = vuetify
        },
        SET_ADDITIONAL(state, additional) 
        {
            state.additional = additional
        },
        SET_TOAST_ACTIVE(state, active) {
            state.toast.active = active
        },
        SET_TOAST_TEXT(state, text) {
            state.toast.text = text
        },
        SET_TOAST_WARNING(state, warning) {
            state.toast.warning = warning
        }
    },
    getters: {
        keyDeploymentChannel: () => DEPLOYMENT_CHANNEL,
        keyDeploymentDebugging: () => DEPLOYMENT_DEBUGGING,
        keyDeploymentUrl: () => DEPLOYMENT_URL,

        keyCookieExpiresMax: () => COOKIE_EXPIRES_MAX,

        keyAppShare: () => APP_SHARE,
        keyAppLogin: () => APP_LOGIN,
        keyAppAccount: () => APP_ACCOUNT,
        keyAppAdmin: () => APP_ADMIN,
        keyAppApps: () => APP_APPS,

        keyAppSketchurizer: () => APP_SKETCHURIZER,
        keyAppModulator: () => APP_MODULATOR,
        keyAppTexturizer: () => APP_TEXTURIZER,
        keyAppBoxurizer: () => APP_BOXURIZER,

        keyWindowApps: () => WINDOW_APPS,
        keyWindowPrototypes: () => WINDOW_PROTOTYPES,
        keyWindowDefault: () => WINDOW_DEFAULT,

        keyDesignModeDefault: () => DESIGN_MODE_DEFAULT,
        keyDesignModeLight: () => DESIGN_MODE_LIGHT,
        keyDesignModeDark: () => DESIGN_MODE_DARK,
        keyDesignThemeDefault: () => DESIGN_THEME_DEFAULT,

        keyResolutionThumbnailDefault: () => RESOLUTION_THUMBNAIL_DEFAULT,

        hasLoadingProgress: state => state.loading.progress >= 0,
        hasLoadingText: state => state.loading.text !== null,
        hasGroup: state => state.group !== null,

        isDebugging: () => DEPLOYMENT_DEBUGGING,
        isInitialized: state => state.initialized,
        isLoadingActive: state => state.loading.active,
        isExperimental: state => {
            if (state.developer) 
            {
                const experimental = state.additional?.appExperimental
                if (experimental) return experimental
            }
            return false
        },
        isAuthenticated: state => state.token != null,
        isPermanent: state => state.permanent,
        isDeveloper: state => state.developer,
        isApps: state => 
            state.permission.sketchurizer, //||
            //state.permission.modulator,
        isPrototypes: state => 
            state.permission.texturizer || 
            state.permission.boxurizer,
        isSketchurizerOnly: state => 
            state.permission.sketchurizer &&
            //!state.permission.modulator &&
            !state.permission.texturizer &&
            !state.permission.boxurizer,
        isSketchurizer: state => state.permission.sketchurizer,
        isModulator: state => state.permission.modulator,
        isTexturizer: state => state.permission.texturizer,
        isBoxurizer: state => state.permission.boxurizer,
        isSketchurizerActive: state => state.appname === APP_SKETCHURIZER,
        isModulatorActive: state => state.appname === APP_MODULATOR,
        isTexturizerActive: state => state.appname === APP_TEXTURIZER,
        isBoxurizerActive: state => state.appname === APP_BOXURIZER,
        isWindowApps: state => state.window === WINDOW_APPS,
        isWindowPrototypes: state => state.window === WINDOW_PROTOTYPES,
        isToastActive: state => state.toast.active,
        isToastWarning: state => state.toast.warning,

        getChannel: () => DEPLOYMENT_CHANNEL,
        getUrl: () => DEPLOYMENT_URL,
        getVersion: state => state.version, 
        getAppname: state => state.appname,
        getFormattedAppname: () => (app) => {
            switch(app) 
            {
                case APP_SHARE:
                    return "Share (Core)"
                case APP_LOGIN:
                    return "Login (Core)"
                case APP_ACCOUNT:
                    return "Account (Core)"
                case APP_ADMIN:
                    return 'Admin (Core)'
                case APP_APPS:
                    return 'Apps (Core)'
                case APP_SKETCHURIZER:
                    return 'Sketchurizer'
                case APP_MODULATOR:
                    return 'Modulator'
                case APP_TEXTURIZER:
                    return 'Texturizer'
                case APP_BOXURIZER:
                    return 'Boxurizer'
            }
        },
        getWindow: state => state.window,
        getLoadingProgress: state => {
            if(isNaN(state.loading.progress)) return 0
            if(state.loading.progress == -1) return -1
            return Math.floor(state.loading.progress / 0.1) * 0.1 // in steps of 10%
        },
        getLoadingText: state => state.loading.text,
        getToken: state => state.token,
        getIssued: state => state.issued,
        getExpires: state => state.expires,
        getUsername: state => state.username,
        getGroup: state => state.group,
        getMode: state => state.design.mode,
        getTheme: state => state.design.theme,
        getVuetify: state => state.design.vuetify,
        getAdditional: state => state.additional,
        getToastText: state => state.toast.text
    },
    actions: {
        async initializeApp({ commit, dispatch, getters }, version)
        {
            // set app version
            commit('SET_VERSION', version)

            // get token
            const cookie_token = useCookies(['token']).get('token')
            if (typeof cookie_token !== 'undefined')
                commit('SET_TOKEN', cookie_token)

            // update design
            await dispatch('updateDesign')

            // check if user is authenticated
            if (getters.isAuthenticated) 
            {
                // get user additional
                await dispatch('fetchAdditional')
            }

            // set initialization to complete
            commit('SET_INITIALIZED')
        },

        setAppname({ commit }, appname)
        {
            commit('SET_APPNAME', appname)
        },

        setWindow({ commit }, window)
        {
            commit('SET_WINDOW', window)
        },
        setWindowToDefault({ commit })
        {
            commit('SET_WINDOW', WINDOW_DEFAULT)
        },
        setWindowToApps({ commit })
        {
            commit('SET_WINDOW', WINDOW_APPS)
        },
        setWindowToPrototypes({ commit })
        {
            commit('SET_WINDOW', WINDOW_PROTOTYPES)
        },

        activateLoading({ commit })
        {
            commit('SET_LOADING_ACTIVE', true)
        },
        deactivateLoading({ commit })
        {
            commit('RESET_LOADING')
        },
        setLoadingProgress({ commit }, progress) 
        {
            commit('SET_LOADING_PROGRESS', progress)
        },
        setLoadingText({ commit }, text)
        {
            commit('SET_LOADING_TEXT', text)
        },

        setExperimental({ getters, dispatch }, experimental) 
        {
            // get additional
            const additional = getters.getAdditional

            // set experimental
            additional.appExperimental = experimental

            // change additional 
            dispatch('changeAdditional', additional)
        },
        setToken({ dispatch, commit }, token) {
            commit('SET_TOKEN', token)
        },
        setPermanent({ commit }, permanent)
        {
            commit('SET_PERMANENT', permanent)
        },
        setVuetify({ commit }, vuetify)
        {
            commit('SET_VUETIFY', vuetify)
        },

        //// USERS

        // register user
        async registerUser({ dispatch, getters }, { 
            email
        }) 
        {
            // call route to register user
            return dispatch('api/usersRegisterPost', { 
                email, 
                mode: getters.getMode,
                theme: getters.getTheme
            }, { root: true })
        },

        // activate user
        async activateUser({ dispatch, commit }, { 
            token
        })
        {
            try
            {
                // call route to activate user
                const response = await dispatch('api/usersActivatePut', { 
                    token
                }, { root: true })

                // set token
                commit('SET_TOKEN', response.access_token)

                // initialize the user
                dispatch('api/initializeUser', null, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // deactivate user
        async deactivateUser({ dispatch, commit })
        {
            try
            {
                // call route to activate user
                const response = await dispatch('api/usersDectivatePut', null, { root: true })

                // set token
                commit('SET_TOKEN', null)

                return response
            }
            catch(error)
            {
                throw error
            }
        },
        
        // login user
        async loginUser({ dispatch, commit }, { 
            email, 
            password
        })
        {
            try
            {
                // call route to activate user
                const response = await dispatch('api/usersTokenPost', { 
                    email,
                    password
                }, { root: true })

                // set token
                commit('SET_TOKEN', response.access_token)

                // initialize user
                dispatch('api/initializeUser', null, { root: true })

                // update user design
                dispatch('updateDesign')

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // logout
        async logoutUser({ commit }, expired = false)
        {
            // return a promise
            return new Promise(async (resolve, reject) => {

                // remove token
                commit('SET_TOKEN', null)

                // navigate to login
                if (expired) {
                    router.push({ path: '/login', query: { expired } })
                } else {
                    router.push('/login')
                }

                resolve()
            })
        },

        // password forgot
        async passwordForgot({ dispatch }, { 
            email
        }) 
        {
            // call route to email password
            return dispatch('api/usersPasswordForgotPost', { 
                email 
            }, { root: true })
        },

        // password reset
        async passwordReset({ dispatch }, { 
            token, 
            password
        })
        {
            // call route to change password
            return dispatch('api/usersPasswordChangePut', {
                token,
                password
            }, { root: true })
        },

        // password change
        async passwordChange({ dispatch }, { 
            password
        })
        {
            // call route to change password
            return dispatch('api/usersPasswordChangePut', {
                password
            }, { root: true })
        },

        // fetch statistics
        async fetchStatistics({ dispatch })
        {
            try
            {
                // call route to fetch user statistics
                const response = await dispatch('api/usersStatisticsGet', null, { root: true })

                // extract create date
                const date = new Date(response.create_date)
                const day = String(date.getUTCDate()).padStart(2, '0')
                const month = String(date.getUTCMonth() + 1).padStart(2, '0')
                const year = date.getUTCFullYear()
                const formattedDate = `${day}.${month}.${year}`

                // generation statistics
                const images = response.statistics.images_generation_count
                const models = response.statistics.model_generation_count
                let textures = 0
                textures += Number(response.statistics.texture_generation_count)
                textures += Number(response.statistics.texture_generic_generation_count)
                textures += Number(response.statistics.texture_custom_generation_count)

                // new modified response
                const modifiedResponse = {
                    since: formattedDate,
                    images,
                    models,
                    textures
                }

                return modifiedResponse
            }
            catch (error)
            {
                throw error
            }
        },

        // update design
        async updateDesign({ dispatch, commit, getters }) 
        {
            const updateDesignFromCookies = () => {

                // handle mode and theme based on cookies
                const cookies = useCookies(['mode', 'theme'])
        
                //// MODE
        
                // variable for mode
                let mode = 'Dark'
        
                // get cookie
                const cookie_mode = cookies.get('mode')
        
                // if cookie exists and is valid, use it
                if (typeof cookie_mode !== 'undefined' && (cookie_mode === 'Light' || cookie_mode === 'Dark')) 
                {
                    mode = cookie_mode
                }
                // otherwise, use system default 
                else 
                {
                    const system_dark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
                    mode = system_dark ? 'Dark' : 'Light'
                }
        
                // set mode
                commit('SET_MODE', mode)
        
                //// THEME
        
                // variable for theme
                let theme = 0
        
                // get cookie
                const cookie_theme = cookies.get('theme')
        
                // if cookie exists and is valid, use it
                if (typeof cookie_theme !== 'undefined' && cookie_theme >= 0 && cookie_theme <= 3) 
                {
                    theme = cookie_theme
                }
        
                // set theme
                commit('SET_THEME', theme)

                // return a resolved promise
                return { mode, theme }
            }

            // cookies as fallback design
            updateDesignFromCookies()

            // check if user is authenticated
            if (getters.isAuthenticated) 
            {
                try 
                {
                    // call route to 
                    const response = await dispatch('api/usersDesignGet', null, { root: true })
        
                    // commit mode and theme based on the response
                    commit('SET_MODE', response.mode)
                    commit('SET_THEME', response.theme)

                    return response
                } 
                catch (error) 
                {
                    // invalidate the token
                    commit('SET_TOKEN', null)

                    // propagate the error
                    throw error
                }
            } 
        },

        // change design
        async changeDesign({ dispatch, commit }, {
            mode,
            theme
        })
        {
            // directly set mode and theme
            commit('SET_MODE', mode)
            commit('SET_THEME', theme)

            // call route to update design
            return dispatch('api/usersDesignPut', {
                mode,
                theme
            }, { root: true })
        },

        // fetch newsletter subscription
        async fetchNewsletterSubscription({ dispatch })
        {
            // call route to fetch newsletter subscription
            return dispatch('api/usersNewsletterGet', null, { root: true })
        },

        // change newsletter subscription
        async changeNewsletterSubscription({ dispatch }, { 
            subscribed
        })
        {
            // call route to change newsletter subscription
            return dispatch('api/usersNewsletterPost', {
                subscribed
            }, { root: true })
        },

        // fetch additional 
        async fetchAdditional({ dispatch, commit })
        {
            try
            {
                // call route to get users additional
                const response = await dispatch('api/usersAdditionalGet', null, { root: true })

                // set additional
                commit('SET_ADDITIONAL', response) 

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // change additional
        async changeAdditional({ dispatch, commit }, additional)
        {
            try
            {
                // call route to set users additional
                const response = await dispatch('api/usersAdditionalPut', 
                    additional, { root: true })

                // set additional
                commit('SET_ADDITIONAL', additional) 

                return response
            }
            catch(error)
            {
                throw error
            }
        },


        //// LOGGING

        // log event
        async logEvent({ getters, dispatch }, {
            name, 
            description
        })
        {
            // do not track events for unauthenticated users
            if (!getters.isAuthenticated) return

            // call route to log event
            return dispatch('api/loggingEventPost', {
                name,
                description
            }, { root: true })
        },

        // log bug
        async logBug({ getters, dispatch }, {
            name, 
            description
        })
        {
            // do not track bugs for unauthenticated users
            if (!getters.isAuthenticated) return

            // call route to log bug
            return dispatch('api/loggingBugPost', {
                name,
                description
            }, { root: true })
        },

        // log feedback
        async logFeedback({ getters, dispatch, rootGetters }, {
            topic,
            feedback
        })
        {
            // help to understand the user's context
            let additional = null
            
            if (getters.isSketchurizerActive)
            {
                additional = {
                    window: rootGetters['sketchurizer/getActiveWindow']
                }
            }
            else if (getters.isTexturizerActive)
            {
                additional = {
                    window: rootGetters['texturizer/getMode']
                }
            }
            else if (getters.isBoxurizerActive)
            {
                additional = {
                    window: rootGetters['boxurizer/getActiveWindow']
                }
            }

            // call route to log feedback
            return dispatch('api/loggingFeedbackPost', {
                topic,
                feedback,
                additional
            }, { root: true })
        },

        // fetch vote
        async fetchVote({ dispatch }, {
            topic
        })
        {
            // call route to fetch vote
            return dispatch('api/loggingVoteGet', {
                topic
            }, { root: true })
        },

        // update vote
        async updateVote({ dispatch }, {
            topic, 
            vote
        })
        {
            // call route to update vote
            return dispatch('api/loggingVotePut', {
                topic,
                vote
            }, { root: true })  
        },


        showToast({ commit }, { text, warning = false }) {
            commit('SET_TOAST_TEXT', text)
            commit('SET_TOAST_WARNING', warning)
            commit('SET_TOAST_ACTIVE', true)
        },
        setToastActive({ commit }, active) {
            commit('SET_TOAST_ACTIVE', active)
        },
        setToastText({ commit }, text) {
            commit('SET_TOAST_ACTIVE', text)
        },
        setToastWarning({ commit }, warning) {
            commit('SET_TOAST_WARNING', warning)
        },  
    }
}