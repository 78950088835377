import { ModelFactory } from '@/classes/factory/ModelFactory.js'

// vuex.js state of 3D model
export const model = {
    namespaced: true,
    state: {
        model: null
    },
    mutations: {
        RESET_MODEL(state) {
            state.model = ModelFactory.newModel()
        },
        SET_MODEL(state, model) {
            state.model = model
        }
    },
    getters: {
        getModel: state => state.model,
        getMeshes: state => state.model?.getMeshes(),
        getMesh: (state, getters) => (meshId) => {
            const meshes = getters.getMeshes
            if (meshes === null) return
            return meshes.find(mesh => mesh.id === meshId)
        },
        getGeometries: (state, getters) => (meshId, filter=0) => {
            const mesh = getters.getMesh(meshId)
            if (mesh === null) return
            const geometries = mesh.nonreactive.geometries
            if (geometries === null) return
            return geometries
        },
        getMaterials: (state, getters) => (meshId, filter=0) => {
            const mesh = getters.getMesh(meshId)
            if (mesh === null) return
            const materials = mesh.nonreactive.materials
            if (materials === null) return
            return materials
        },
        getVersion: state => state.model?.reactive.version,
        isImported: state => state.model?.reactive.ready
    },
    actions: {   
        resetModel({ commit }) {
            commit('RESET_MODEL')
        },
        setModel({ commit }, model) {
            console.log(model)
            commit('SET_MODEL', model)
        }
    }
}